import * as URLs from '../network/baseUrl';

//Redux reducers
import { userLogin, loginFailed, userLogoff } from './accountSlice';
import { redirectAction, resetRedirect } from './urlSlice';
import { setData, setProcessing, resetData } from './creditReportSlice';

//Networking import
import axios from 'axios';

//Popups
import Swal from 'sweetalert2'

/**
 * Login to an existing account
 * @param {*} email           Email of the sales agent
 * @param {*} password        Password of the sales agent
 * @returns 
 */
async function loginForm (email, password) {
        
        // Create an object of formData
        const formData = new FormData();

        // Add the field with the UserID
        formData.append("email", email);

        // Add the field with Anon status
        formData.append("password", password);

        let response = await axios.post(URLs.loginURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}


export const loginUser = (email, password) => (dispatch) => {

        loginForm(email, password)
                .then((data) => {
                        if (data['code'] === 0) {
                                dispatch(loginFailed(data['msg']));

                                Swal.fire("Error", data['msg'], 'error')
                        }  
                        else {
                                let account = data['data'];
                                localStorage.setItem("CLGSession", account['session']);
                                dispatch(userLogin(account));

                                Swal.fire("Login Succesful", "Welcome back " + account['name'], 'success');
                                //Swal.fire(data['msg'])
                        }  
                })
                .catch(error => {
                        dispatch(loginFailed("Failed to Login"));
        });
}

/**
 * Autologin to an existing account through session coookie     
 * @param {*} cookie       Cookie of the sales agent                            
 * @returns 
 */
async function cookieLoginForm (cookie) {
        
        // Create an object of formData
        const formData = new FormData();

        // Add the field with the cookie
        formData.append("cookie", cookie);

        let response = await axios.post(URLs.loginURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}


export const cookieLoginUser = (cookie) => (dispatch) => {

        cookieLoginForm(cookie)
                .then((data) => {
                        if (data['code'] === 0) {
                                dispatch(loginFailed(data['msg']));

                                Swal.fire("Error", data['msg'], 'error')

                        }  
                        else {
                                let account = data['data'];

                                dispatch(userLogin(account));
                        }  
                })
                .catch(error => {
                        dispatch(loginFailed("Failed to Login"));
        });
}

/**
 * The user would lke to logout of the machine
 */ 
export const logoutUser = () => (dispatch) => {
        localStorage.removeItem("CLGSession");
        dispatch(userLogoff());
}

/**
 * Register a new Sales consultant to the system
 * @param {*} name          Name of the Consultant
 * @param {*} email         Email of the Consultant
 * @param {*} password      New Password for the Consultant
 * @returns 
 */
async function registerForm (name, email, password) {
        
        // Create an object of formData
        const formData = new FormData();

        // Add the field with the name
        formData.append("name", name);

        // Add the field with the email
        formData.append("email", email);

        // Add the field with password
        formData.append("password", password);

        let response = await axios.post(URLs.registerURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}


export const registerUser = (name, email, password) => (dispatch) => {

        registerForm(name, email, password)
                .then((data) => {
                        if (data['code'] === 0) {
                                dispatch(loginFailed(data['msg']));

                                Swal.fire("Error", data['msg'], 'error')
                        }  
                        else {
                                let account = data['data'];
                                localStorage.setItem("CLGSession", account['session']);
                                dispatch(userLogin(account));

                                Swal.fire("Registration Success", "You have been successfully registered", 'success')
                        }  
                })
                .catch(error => {
                        dispatch(loginFailed("Failed to Register"));
        });
}

/**
 * Register a new Sales consultant to the system
 * @param {*} email         Email of the Consultant
 * @returns 
 */
async function forgotPasswordForm (email) {
        
        // Create an object of formData
        const formData = new FormData();

        // Add the field with the email
        formData.append("email", email);

        let response = await axios.post(URLs.forgotPasswordURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}


export const forgotPassword = (email) => (dispatch) => {

        forgotPasswordForm(email)
                .then((data) => {
                        if (data['code'] === 0) {
                                dispatch(loginFailed(data['msg']));

                                Swal.fire("Error", data['msg'], 'error')
                        }  
                        else {
                                Swal.fire("Reset Password", "Please check your email for the reset link", 'success')
                        }  
                })
                .catch(error => {
                        dispatch(loginFailed("Failed to Register"));
        });
}

/**
 * Resets a password for a user
 * @param {*} token         Email of the Consultant
 * @param {*} password      New Password for the Consultant
 * @returns 
 */
async function resetPasswordForm (token, password) {
        
        // Create an object of formData
        const formData = new FormData();

        // Add the field with the token
        formData.append("recoveryKey", token);

        // Add the field with the new password
        formData.append("password", password);

        let response = await axios.post(URLs.resetPasswordURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}


export const resetPassword = (token, password) => (dispatch) => {
        
        resetPasswordForm(token, password)
                .then((data) => {
                        if (data['code'] === 0) {
                                dispatch(loginFailed(data['msg']));

                                Swal.fire("Error", data['msg'], 'error')
                        }  
                        else {

                                Swal.fire("Password reset", "Your password has been successfully reset, please login again with your new password.", 'success')
                                
                                dispatch(redirectAction('/'));
                        }  
                })
                .catch(error => {
                        dispatch(loginFailed("Failed to change password"));
        });
}

export const resetRedirectAction = () => (dispatch) => {

        dispatch(resetRedirect());
}

/**
 * Send a client's Credit Report and return analytical data 
 */
async function loadAnalyticsForm (creditReport, clientID, clientName, clientEmail, consultantID) {

        // Create an object of formData
        const formData = new FormData();

        // Add the field with the Credit Report object from Array.com
        formData.append("creditReport", JSON.stringify(creditReport));

        // Append the Id of the client
        formData.append("clientID", clientID);

        // Append the name of the client
        formData.append("name", clientName);

        // Append the email of the client
        formData.append("email", clientEmail);

        // Append the ID of the consultant
        formData.append("consultantID", consultantID);

        let response = await axios.post(URLs.analyzeURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}

export const loadAnalytics = (creditReport, clientKey, clientName, clientEmail) => (dispatch) => {

        loadAnalyticsForm(creditReport, clientKey, clientName, clientEmail)
                .then((data) => {
                        if (data['code'] === 0) {
                                dispatch(loginFailed(data['msg']));

                                Swal.fire("Error", data['msg'], 'error')
                        }  
                        else {
                                let analytics = data['data'];
                                dispatch(setData(analytics));
                        }  
                })
                .catch(error => {
                        dispatch(loginFailed("Failed to load analytics"));
        });
}

export const getCredit = (first_name, last_name, email, clientID, consultantID) => (dispatch) => {

        //Set Processing to be true
        dispatch(setProcessing(true));

        analyzeClientCredit(first_name, last_name, email, clientID, consultantID).then((data) => {

                if (data['code'] === 0) {
                        dispatch(resetData());

                        Swal.fire("Error", data['msg'], 'error')
                }  
                else {
                        let analytics = data['data'];
                        dispatch(setData(analytics));
                }  
        }).catch(error => {
                Swal.fire("Error", error.message, 'error');
                dispatch(resetData());
        });
}

/** 
 * Analyze the client's credit report
*/
async function analyzeClientCredit (first_name, last_name, email, clientID, consultantID) {

        // Create an object of formData
        const formData = new FormData();

        // Append the Name of the client
        formData.append("clientName", first_name + ' ' + last_name);

        // Append the Id of the client
        formData.append("clientID", clientID);

        // Append the Email of the client
        formData.append("email", email);

        // Append the Email of the client
        formData.append("consultantID", consultantID);

        let response = await axios.post(URLs.analyzeURL, formData);

        //Get the Data
        const data = await response.data;

        return data;
}

/**
 * Upload file to Server */ 
async function uploadReport_call (file, clientName, clientID, clientEmail, consultantEmail) {

        // Create an object of formData
        const formData = new FormData();

        // Add Blob to formData
        formData.append("file", file);

        // Append the Name of the client
        formData.append("clientName", clientName);

        // Append the Id of the client
        formData.append("clientID", clientID);

        // Append the Email of the client
        formData.append("clientEmail", clientEmail);

        // Append the Email of the client
        formData.append("consultantEmail", consultantEmail);
        

        let response = await axios.post(URLs.uploadReport, formData);

        //Get the Data
        const data = await response.data;

        return data;
}

export const uploadReport = (file, clientName, clientID, clientEmail, consultantEmail) => (dispatch) => {
        
        uploadReport_call(file, clientName, clientID, clientEmail, consultantEmail)
                .then((data) => {
                        if (data['code'] === 0) {
                                Swal.fire("Error", data['msg'], 'error')
                        }  
                        else {
                                Swal.fire("Complete", 'Credit Reported Emailed to ' + clientName, 'success')
                        }  
                })
                .catch(error => {
                        Swal.fire("Error", "Upload error", 'error');
        });
}