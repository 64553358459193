import { createSlice } from '@reduxjs/toolkit'

const staffReducer = createSlice({
  name: 'SalesStaff',
  initialState: {
    account: null,
  },
  reducers: {
    userLogin(state, action) {
        state.account = action.payload
    },
    loginFailed(state, action) {
        state.account = null
    },
    userLogoff(state) {
        state.account = null
    }
  }
})

export const { userLogin, loginFailed, userLogoff } = staffReducer.actions
export default staffReducer.reducer