import { createSlice } from '@reduxjs/toolkit'

const urlReducer = createSlice({
  name: 'URL',
  initialState: {
    route: '',
  },
  reducers: {
    redirectAction(state, action) {
        state.route = action.payload
    },
    resetRedirect(state, action) {
        state.route = ''
    }
  }
})

export const { redirectAction, resetRedirect } = urlReducer.actions
export default urlReducer.reducer