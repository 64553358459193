
import React from 'react';
import { Link } from 'react-router-dom';

import { MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

//CSS
import './footer.css';

const Footer = () => {

  const imageUrl = process.env.PUBLIC_URL + '/logo.png';

  return (
    <footer>
        <hr />
        <div className="logoFooter">
            <div className="row">
                <div className="col">
                    <div className='row'>
                        <img src={imageUrl} className="img-logoFooter" alt="DebtFixer Logo" />
                    </div>
                </div>
                <div className="col">
                    <span className='alignCenterLeftFooter'>
                        DebtFixer ©2023
                    </span>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;