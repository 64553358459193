import './App.css';


//Login and Registration pages
import Login from './components/LoginComponent/login.js';
import Register from './components/RegisterComponent/register.js';

//Forgot Password
import ForgotPassword from './components/ForgotPasswordComponent/forgotPassword';

//Forgot Password
import ResetPassword from './components/ResetPasswordComponent/resetPassword';

//Client Input
import Clientinput from './components/ClientInputComponent/clientinput';

//Analysis
import Analysis from './components/AnalysisComponent/analysis';

//Routing for Reactjst
import { Route, Routes } from 'react-router-dom';


const App = () => {
    return (
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/client" element={<Clientinput />} />
          <Route path="/analysis" element={<Analysis />} />
        </Routes>
      </>
    );
}

export default App;
