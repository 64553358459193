import React, { Component } from 'react';

import {
          MDBBtn,
          MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
        }
from 'mdb-react-ui-kit';

//CSS
import './clientInput.css'; 

//Loading Animation
import PacmanLoader from "react-spinners/PacmanLoader";
import { Wave } from "react-animated-text";

import { connect } from 'react-redux';
import { getCredit, cookieLoginUser } from '../../redux/ActionCreator';
import Header from '../HeaderComponent/header';
import Footer from '../FooterComponent/footer';

//Credit Report Graphing page
import Analysis from '../AnalysisComponent/analysis';

//Routing
import { Navigate } from "react-router-dom";

//Sweet Alert
import Swal from 'sweetalert2';

const mapStateToProps = state => {
    return {
      account: state.account,
      creditReport: state.creditReport
    }    
}

const mapDispatchToProps = (dispatch) => ({
  getCredit: (first_name, last_name, email, clientID, consultantID) => {dispatch(getCredit(first_name, last_name, email, clientID, consultantID))},
  cookieLoginUser: (session) => {dispatch(cookieLoginUser(session))}
});

class ClientLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            clientid: '',
        }
        this.buttonPressed = this.buttonPressed.bind(this);
    }

    /**
     * Autologin with Cookie
     */
    componentDidMount() {

      const existingSession = localStorage.getItem('CLGSession'); 
            
      //If we have a cookie, then login the user
      if (existingSession !== undefined && existingSession !== null) {
        this.props.cookieLoginUser(existingSession);
      }
    }

    /**
     * This function is called when the login button is pressed
     * @param e  The event object         
     */
    buttonPressed(e) {
        e.preventDefault();

        //Make sure the user entered a proper email
        if ((this.state.email === '') || (this.state.email.indexOf('@') === -1)) {
          Swal.fire({
            title: 'Error!',
            text: 'Please enter a valid email address',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        
        //Make sure user entered a First Name
        else if (this.state.first_name === '') {
          Swal.fire({
              title: 'Error!',
              text: 'Please enter a First Name',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }

        //Make sure user entered a Last Name
        else if (this.state.last_name === '') {
          Swal.fire({
              title: 'Error!',
              text: 'Please enter a Last Name',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }

        //Make sure user entered a Client ID
        else if (this.state.clientid === '') {
          Swal.fire({
              title: 'Error!',
              text: 'Please enter a Client ID',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }

        else {
          //Make a couple API calls
          this.props.getCredit(this.state.first_name, this.state.last_name, this.state.email, this.state.clientid, this.props.account.account._id);
        }
    }

    /**
     * The Input Form function
     * @returns The JSX for the input form
     */
    inputForm() {
        return (
            <MDBContainer className="w-50">
              <MDBRow>
                <MDBCol md="6">
                  {/* Left Column */}
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label text-start font-weight-bold">First Name</label>
                    <MDBInput label='First name' id='form1' type='text' onChange={ (e) => this.setState({ first_name: e.target.value }) }/>
                  </div>
                </MDBCol>
                <MDBCol md="6" className="text-start">
                  {/* Right Column */}
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label font-weight-bold">Last Name</label>
                    <MDBInput label='Last name' id='form1' type='text' onChange={ (e) => this.setState({ last_name: e.target.value }) }/>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label text-start font-weight-bold">Email</label>
                    <MDBInput label='you@company.com' id='form1' type='text' onChange={ (e) => this.setState({ email: e.target.value }) }/>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {/* ClientID */}
                  <div className="mb-3">
                    <label htmlFor="clientID" className="form-label text-start font-weight-bold">ClientID</label>
                    <MDBInput label='DebtPayPro ClientID' id='form1' type='text' onChange={ (e) => this.setState({ clientid: e.target.value }) }/>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          );
        }
    
    /**
     * The return statement contains the JSX that will be rendered
     */
    render() {
      
      //Return to Login Screen once the user logs out
      if (this.props.account.account === null) {

        const existingSession = localStorage.getItem('CLGSession'); 
            
        //If we have a cookie, then login the user
        if (existingSession === undefined || existingSession === null) {
          return (
            <Navigate to="/" />
          );
        }
      }
 
      else {
        //Button has been clicked and we are getting the credit report
        if (this.props.creditReport.processing) {
        
          const color = "#FFFF00";
          const loading = true;

          return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: 'lightgrey',
                flexDirection: 'column'
                }}>
                <PacmanLoader
                    color={color}
                    loading={loading}
                    size={75}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                <br />
                <p style={{fontWeight:'bolder', fontSize: "26px" }}><Wave text="Generating AI results" effect="fadeOut" effectChange={0.5} /></p>
            </div>
          ) 
        }
        else if (this.props.creditReport.data !== '') {

          return (
            <Analysis client={this.state.first_name + " " + this.state.last_name} email={this.state.email} clientID={this.state.clientid}/>
          );
        }
        else {
          return (
              <MDBContainer className="my-5">
                  <Header />
                  <MDBRow>
                      <div>
                          <h1 className="d-flex flex-row text-center w-50 mb-4">Client Intake: The First Step Towards Improved Credit</h1>
                      </div>
                      <div className="d-flex flex-row text-center w-50 align-items-center justify-content-center mb-5">
                          <p>Edit Your Client's Details To Kickstart Their Credit Repair Journey</p>
                      </div>
                      <div>
                          { this.inputForm() }     
                      </div>
                      <div className="text-center pt-1 mb-1 pb-1">
                          <MDBBtn className="mb-4 w-50 h-75" onClick={this.buttonPressed}>Submit</MDBBtn>
                      </div>
                  </MDBRow>
                  <Footer />
              </MDBContainer> 
          );
        }
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientLogin);