import React, { Component } from 'react';

import {
          MDBBtn,
          MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
        }
        
from 'mdb-react-ui-kit';
import './resetPassword.css';

import { connect } from 'react-redux';
import { resetPassword } from '../../redux/ActionCreator';

//Routing
import { Link, useParams } from 'react-router-dom';

//SweetAlert
import Swal from 'sweetalert2';

//Login Page
import Login from '../LoginComponent/login';

const mapStateToProps = state => {
    return {
      login: state.account,
      url: state.url
    }    
}

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (token, password) => { dispatch(resetPassword(token, password)) },
});

export function withRouter(Children){
    return(props)=>{

       const match  = {params: useParams()};
       return <Children {...props}  token_session = {match}/>
   }
 }

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            token: props.token_session.params.token,
            password: '',
            password_confirm: ''
        }
        this.buttonPressed = this.buttonPressed.bind(this);
    }

    /**
     * This function is called when the login button is pressed
     * @param e  The event object         
     */
    buttonPressed(e) {
        e.preventDefault();
        if (this.state.password !== this.state.password_confirm) {
            Swal('Passwords do not match','Please re-enter your passwords','error');
            return;
        }
        else {
            this.props.resetPassword(this.state.token, this.state.password);
        }
    }

    /**
     * The return statement contains the JSX that will be rendered
     */
    render() {
        
        if (this.props.url.route !== '') {
            window.history.replaceState(null, "Login", "/");
            return (
                <Login />
            );
        }
        else {
            return (
                <MDBContainer className="my-5 gradient-form">
            
                <MDBRow>
                    <MDBCol col='6' className="mb-5">
                    <div className="d-flex flex-column ms-5">
            
                        <div className="text-center">
                        <img src="logo.png"
                            style={{width: '185px'}} alt="logo" />
                        <h4 className="mt-5 mb-1 pb-1">Reset Password</h4>
                        </div>
            
                        <h6 className='mt-1 label-align-left'>Password</h6>
                        <MDBInput wrapperClass='mb-4' label='Create a new password' id='form1' type='text' onChange={ (e) => this.setState({ password: e.target.value }) }/>
                        
                        <h6 className='mt-1 label-align-left'>Confirm Password</h6>
                        <MDBInput wrapperClass='mb-4' label='Confirm your password' id='form2' type='text' onChange={ (e) => this.setState({ password_confirm: e.target.value }) }/>
            
                        <div className="text-center pt-1 mb-5 pb-1">
                        <MDBBtn className="mb-4 w-100 gradient-custom-2" onClick={this.buttonPressed}>Reset Password</MDBBtn>
                        </div>
            
                        <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                        <p className="mb-0">Already have an account?</p>
                        <MDBBtn outline className='mx-2' color='primary'>
                            <Link to="/">Sign in</Link>
                        </MDBBtn>
                        </div>
            
                    </div>
            
                    </MDBCol>
            
                    <MDBCol col='6' className="mb-5">
                    <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
            
                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                        <h1 className="mb-1 label-align-left">Unlock your</h1>
                        <h1 className="mb-4 label-align-left">potential with AI</h1>
                        <p className="medium mb-0 label-align-left">Welcome to our AI-Enabled Credit Analysis and Repair Platform! Empower yourself
                            with cutting-edge technnology to provide efficient, personalized credit repair services that truly make a difference.
                        </p>
                        <br/>
                        <p className="medium mb-0 label-align-left">As a sales representative, you will gain access to our robust AI tools that instantly analyze credit reports, 
                        identify disputable accounts, suggest possible debt settlements, and propose a tailor-made credit improvement plan for your clients. This
                        unique platform streamlines your work, freeing you to foxus more on client relationships and less on manual analysis. 
                        </p>
                        </div>
            
                    </div>
            
                    </MDBCol>
            
                </MDBRow>

                {/* Footer */}
                <MDBRow>
                    <MDBCol className="mb-5">
                    <p className="bet_time">DebtFixer ©2023</p>
                    </MDBCol>
                </MDBRow>
                
                </MDBContainer>
                
            );
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));