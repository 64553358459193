import React, { Component } from 'react';

import {
          MDBBtn,
          MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
        }
        
from 'mdb-react-ui-kit';
import './forgotPassword.css';

import { connect } from 'react-redux';
import { forgotPassword } from '../../redux/ActionCreator';

//Routing
import { Link, Navigate } from 'react-router-dom';

const mapStateToProps = state => {
    return {
      login: state.account
    }    
}

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => {dispatch(forgotPassword(email))},
});

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            reset: false,
        }
        this.buttonPressed = this.buttonPressed.bind(this);
    }

    /**
     * This function is called when the login button is pressed
     * @param e  The event object         
     */
    buttonPressed(e) {
        e.preventDefault();
        this.props.forgotPassword(this.state.email);

        //Delay for 5 seconds
        setTimeout(() => {
          this.setState({reset: true});
        }, 5000);        
    }

    /**
     * The return statement contains the JSX that will be rendered
     */
    render() {

      //Button Clicked
      if (this.state.reset) {
          return (
            //If we have a cookie, then login the user
              <Navigate to="/" />
          );
      }
      else {
        
        return (
            <MDBContainer className="my-5 gradient-form">
        
              <MDBRow>
        
                <MDBCol col='6' className="mb-5">
                  <div className="d-flex flex-column ms-5">
        
                    <div className="text-center">
                      <img src="logo.png"
                        style={{width: '185px'}} alt="logo" />
                      <h5 className="mt-5 mb-1 pb-1">Forgot Password</h5>
                    </div>
        
                    <p className='text-center'>Please enter the email you registered with</p>
        
                    <MDBInput wrapperClass='mb-4' label='Recovery email address' id='form1' type='email' onChange={ (e) => this.setState({ email: e.target.value }) }/>        
        
                    <div className="text-center pt-1 mb-5 pb-1">
                      <MDBBtn className="mb-4 w-100 gradient-custom-2" onClick={this.buttonPressed}>Recover Account</MDBBtn>
                    </div>
        
                    <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                      <p className="mb-0">Don't have an account?</p>
                      <MDBBtn outline className='mx-2' color='primary'>
                        <Link to="/register">Create New</Link>
                      </MDBBtn>
                    </div>
        
                  </div>
        
                </MDBCol>
        
                <MDBCol col='6' className="mb-5">
                  <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
        
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 class="mb-4">We are more than just a company</h4>
                      <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      </p>
                    </div>
        
                  </div>
        
                </MDBCol>
        
              </MDBRow>
        
              {/* Footer */}
              <MDBRow>
                <MDBCol className="mb-5">
                  <p className="bet_time">DebtFixer ©2023</p>
                </MDBCol>
              </MDBRow>

            </MDBContainer>
          );
      }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);