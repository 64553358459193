import { configureStore } from '@reduxjs/toolkit';

import accountReducer from './accountSlice';
import urlReducer from './urlSlice';
import creditReportReducer from './creditReportSlice';

//import logger from 'redux-logger';

const store = configureStore({
    reducer: {
        account: accountReducer,
        url: urlReducer,
        creditReport: creditReportReducer
    },
    //Logger for Debugging, remove for production
    //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;