import React, { Component } from 'react';

import { connect } from 'react-redux';

//Redux
import { uploadReport } from '../../redux/ActionCreator';

//Loading Animation
import PacmanLoader from "react-spinners/PacmanLoader";
import { Wave } from "react-animated-text";

//Chartjs 
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, BarElement, CategoryScale, plugins } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

//MDBReact
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';

//Header and Footer
import Header from '../HeaderComponent/header';
import Footer from '../FooterComponent/footer';

//CSS
import './analysis.css';

//PDF
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

//Routing
import { Navigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, BarElement, CategoryScale);

const mapStateToProps = state => {
    return {
      creditReport: state.creditReport,
      account: state.account
    }    
}

const mapDispatchToProps = (dispatch) => ({
    uploadReport: (report, clientName, clientID, clientEmail, consultantEmail) => {dispatch(uploadReport(report, clientName, clientID, clientEmail, consultantEmail))},
});

//Formatter for currency
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class Analysis extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditData: '',
        }
    }
      
    /**
     * Build the Credit Score Speedometer Chart with DonutChart
     * @returns 
     */
    buildCreditScoreChart(creditScore) {
            
        //Error check as credit score max is 850 and min is 300
        if (creditScore > 850) {
            creditScore = 850;
        }
        else if (creditScore < 300) {
            creditScore = 300;
        }

        const data = {
            datasets: [{
                data: [500, 500, 500],
                backgroundColor: function(context) {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 300, 0);
                    gradient.addColorStop(0.0, 'red');
                    gradient.addColorStop(0.5, 'yellow');
                    gradient.addColorStop(1, 'green');
                    return gradient;
                  },
                backgroundBorder: ["red", "#FFCE56", "green"],
                borderWidth: 1
            }]
        };

        const options = {
            rotation: -90,
            circumference: 180
        }

        //Center text in Donut Chart
        const textCenter = {
            id: 'Credit Score',
            beforeDatasetsDraw: function(chart, args, options) {
                const { ctx } = chart;
                ctx.save();

                const xCoord = chart.getDatasetMeta(0).data[0].x;
                const yCoord = chart.getDatasetMeta(0).data[0].y + 30;
                
                ctx.font = "30px bolder";
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = 'black';
                ctx.font = "bold 36px serif";
                ctx.fillText(creditScore, xCoord, yCoord);
            }
        }

        let radianAngle = Math.PI + (1 / 550 * (creditScore - 300) * Math.PI);
        const radius = 100;

        //Draw the needle
        const drawNeedle = {
            id: 'Needle',
            afterDraw: function(chart, args, options) {
                const { ctx } = chart;
                ctx.save();

                const xCoord = chart.getDatasetMeta(0).data[0].x;
                const yCoord = chart.getDatasetMeta(0).data[0].y;

                var cx = xCoord;
                var cy = yCoord;
        
                ctx.translate(cx, cy);
                ctx.rotate(radianAngle);
                ctx.beginPath();
                ctx.moveTo(0, -5);
                ctx.lineTo(radius, 0);
                ctx.lineTo(0, 5);
                ctx.fillStyle = 'black';
                ctx.fill();
                
                ctx.rotate(-radianAngle);
                ctx.translate(-cx, -cy);
                ctx.beginPath();
                ctx.arc(cx, cy, 7, 0, Math.PI * 2);
                ctx.fill();
            }
        }

        //Initialize the score title and subtext
        let scoreTitle = '';
        let scoreSubtext = '';

        if (creditScore > 800) {
            scoreTitle = "Your credit score is excellent";
            scoreSubtext = "You are eligible for the best interest rates and terms"
        }
        else if (creditScore > 740) {
            scoreTitle = "Your credit score is very good";
            scoreSubtext = "You are eligible for favorable interest rates and terms"
        }
        else if (creditScore > 670) {
            scoreTitle = "Your credit score is good";
            scoreSubtext = "You are eligible for average interest rates and terms"
        }
        else if (creditScore > 580) {
            scoreTitle = "Your credit score is fair";
            scoreSubtext = "Please review this report for action items to improve your score"
        }
        else {
            scoreTitle = "Your credit score is poor";
            scoreSubtext = "Please review this report to see the main factors for why your score is poor and for action items to improve your score"
        }

        return (
            <MDBCard className='ms-4'>
                <MDBCardBody>
                    <MDBCardTitle>Credit Score</MDBCardTitle>
                    <MDBCardText>
                        Your credit score is {creditScore}
                    </MDBCardText>
                    <div style={{height: '200px' }}>
                        <Doughnut data={data} options={options} plugins={[textCenter, drawNeedle]} />
                    </div>
                    <MDBCardTitle>{ scoreTitle }</MDBCardTitle>
                    <MDBCardText>
                        { scoreSubtext }
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        )
    }

    /**
     * Render Debt Balances Chart
     * @param {*} debtBalances 
     */
    buildDebtBalancesChart(debtBalances) {

        //Labels
        const labels =  Object.entries(debtBalances).map(([key, value]) => { return key; });
        const values =  Object.entries(debtBalances).map(([key, value]) => { return value; });

        const options = {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: 'Debt Balances',
                },
            },
        };

        const data = {
            labels,
            datasets: [
                {
                    label: 'Debt',
                    data: values,
                    backgroundColor: '#175CD3',
                }
            ]
        };

        return (
            <MDBCard className="me-4">
                <MDBCardBody>
                    <MDBCardTitle>Debt Balances</MDBCardTitle>
                    <div style={{height: '325px' }}>
                        <Bar options={options} data={data} />
                    </div>
                </MDBCardBody>
            </MDBCard>
        );

    }

    /**
     * Build Type of Debt Chart
     */
    buildTypeOfDebtChart(typeOfDebt) {

        const labels =  Object.entries(typeOfDebt).map(([key, value]) => { return key; });
        const values =  Object.entries(typeOfDebt).map(([key, value]) => { return value; });

        //Formatter for currency
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const data = {
            labels: ['Secured: ' + currencyFormatter.format(values[0]),'Unsecured: ' + currencyFormatter.format(values[1])],
            datasets: [
                {
                    label: 'Type of Debt',
                    data: values,
                    backgroundColor: [
                        '#175CD3',
                        '#12B76A',
                    ],
                },
            ],
        };

        const commonOptions = {
            responsive: true,
            plugins: {
              legend: {
                position: 'right',
              },
            }
        };

        return (
            <MDBCard className='ms-4 mt-4'>
                <MDBCardBody>
                    <MDBCardTitle>Type of Debt</MDBCardTitle>
                    <div style={{height: '400px' }}>
                        <Doughnut data={data} options={commonOptions} />;
                    </div>
                </MDBCardBody>
            </MDBCard>
        );
    }

    /**
     * Credit Score Factors
     */
    creditScoreFactors(creditScoreFactors) {

        //Formatter for currency
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return (
            <MDBCard className='me-4 mt-4'>
                <MDBCardBody>
                    <MDBCardTitle>Credit Score Factors</MDBCardTitle>
                    <MDBRow>
                        <MDBCol>
                            <MDBCardText className='factorBox'>
                                <p className='factorTitle'>Payments Due</p>
                                <p className='factorText'>{currencyFormatter.format(creditScoreFactors['Payments Due'])}</p>
                            </MDBCardText>
                        </MDBCol>
                        <MDBCol>
                            <MDBCardText className='factorBox'>
                                <p className='factorTitle'>Total Debt Balance</p>
                                <p className='factorText'>{currencyFormatter.format(creditScoreFactors['Total Debt Balance'])}</p>
                            </MDBCardText>
                        </MDBCol>
                        <MDBCol>
                            <MDBCardText className='factorBox'> 
                                <p className='factorTitle'>Charge Offs</p>
                                <p className='factorText'>{creditScoreFactors['Charge Offs']}</p>
                            </MDBCardText>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <MDBCardText className='factorBox'>
                                <p className='factorTitle'>Payments Past Due</p>
                                <p className='factorText'>{currencyFormatter.format(creditScoreFactors['Payments Past Due'])}</p>
                            </MDBCardText>
                        </MDBCol>
                        <MDBCol>
                            <MDBCardText className='factorBox'>
                                <p className='factorTitle'>Credit Usage</p>
                                <p className='factorText'>{creditScoreFactors['Credit Usage']}</p>
                            </MDBCardText>
                        </MDBCol>
                        <MDBCol>
                            <MDBCardText className='factorBox'>
                                <p className='factorTitle'>Collections</p>
                                <p className='factorText'>{creditScoreFactors['Collections']}</p>
                            </MDBCardText>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        );
    }

    /**
     * Export entire screen to PDF 
     */
    async exportToPDF(e) {
    
        //Stop button from submitting form
        e.preventDefault();

        //const componentHtml = this.renderReport();

        const input = document.getElementById('root'); // Assuming your root element has the id 'root'

        //Hide Buttons in PNG file
        // Select the elements you want to hide
        const elementsToHide = document.querySelectorAll('.web-only'); // Replace 'your-class-name' with the actual className of the div(s) you want to exclude

        //const elementsToHide = input.getElementsByClassName('web-only'); // Update the selector to target the specific buttons you want to exclude

        // Hide the selected elements
        elementsToHide.forEach((element) => {
            element.style.display = 'none';
        });

        const images = input.getElementsByTagName('img');
        const promises = [];

        // Wait for all images to finish loading
        for (let i = 0; i < images.length; i++) {
            const promise = new Promise((resolve, reject) => {
            if (images[i].complete) {
                resolve();
            } else {
                images[i].onload = resolve;
                images[i].onerror = reject;
            }
            });
            promises.push(promise);
        }

        // Once all images are loaded, capture the screenshot
        Promise.all(promises)
        .then(() => {
            html2canvas(input)
                .then((canvas) => {
                    const pngData = canvas.toDataURL('image/png');

                    // Convert the canvas to PDF
                    const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height], true);
                    pdf.addImage(pngData, 'PNG', 0, 0, canvas.width, canvas.height, '', 'FAST');
                    
                    //Outputs the PDF to local machine
                    //pdf.save('report.pdf');

                    //Output as Blob
                    const blob = pdf.output('blob');

                    //Upload to Server to email client
                    this.props.uploadReport(blob, this.props.client, this.props.clientID, this.props.email, this.props.account.account.email);
                    
                    // Code to just output as PNG
                    /*const link = document.createElement('a');
                    link.href = pngData;
                    link.download = 'page.png';
                    link.click();*/

                    // Restore the visibility of the hidden elements
                    elementsToHide.forEach((element) => {
                        element.style.display = 'block'; // Use the appropriate display property based on your styling
                    });
                })
                .catch((error) => {
                    console.error('Error converting to PNG:', error);

                    elementsToHide.forEach((element) => {
                        element.style.display = 'block'; // Use the appropriate display property based on your styling
                    });
                });
            })
    }

    /**
     * Render the Report
     * @returns            Report   
     */
    renderReport() {

        const pdfClass = 'pdf-only'; // CSS class to hide the buttons during PDF generation
        const webClass = 'web-only'; // CSS class to show the buttons on the webpage

        const unSecuredDebt = this.props.creditReport.data['unsecured_debt'].map((item, index) => {

            return (
                <MDBListGroupItem key={index}>
                    ✅ Send Letter of Representation, Cease and Desist Letter, and Dispute Letter to {item["creditor"]} for debt of {parseFloat(item["balance"]).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                </MDBListGroupItem>
            );
        });

        return (
            <MDBContainer className="my-5" id='report'>
                <Header />
                <MDBRow className='d-flex justify-content-center align-items-center mb-5'>
                    <MDBCol md='9'>
                        <h2>Credit Analysis Report for {this.props.client}</h2>
                    </MDBCol>
                    <MDBCol md='1'>
                        <MDBBtn className={webClass} color='primary' href="/client">New Client</MDBBtn>
                    </MDBCol>
                    <MDBCol md='1'>
                        <MDBBtn className={webClass} color='primary' onClick={((e) => this.exportToPDF(e))}>Email Client</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <div className='row'>
                        <div className='col-4'>
                            {this.buildCreditScoreChart(this.props.creditReport.data['credit_score'])}  
                        </div>
                        <div className='col-8'>
                            {this.buildDebtBalancesChart(this.props.creditReport.data['debt_balances'])}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            { this.buildTypeOfDebtChart(this.props.creditReport.data['debt_type']) }
                        </div>
                        <div className='col-8'>
                            { this.creditScoreFactors(this.props.creditReport.data['credit_score_factors']) }
                        </div>
                    </div>
                </MDBRow>
                <MDBRow className='mt-5'>
                    <p className='aiTextStyleSubTitle'>DebtFixer Analysis</p>
                    <h2>What are the main factors that affected your credit score?</h2>
                    <span style={{whiteSpace: "pre-wrap"}}>{this.props.creditReport.data['reasons']}</span>
                </MDBRow>
                <MDBRow className='mt-5'>
                    <p className='aiTextStyleSubTitle'>DebtFixer Analysis</p>
                    <h2>Here are legal actions that can be taken to improve your financial situation:</h2>
                    <span style={{whiteSpace: "pre-wrap"}}>
                        <MDBListGroup style={{ minWidthL: '22rem' }} light>
                            {unSecuredDebt}
                        </MDBListGroup>
                    </span>
                </MDBRow>
                <Footer />
            </MDBContainer>
        )

    }
    /**
     * Render Report
     * @returns 
     */
    render(props) {

        const color = "#FFFF00";
        const loading = true;

        if (this.props.creditReport.data === '') {
            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    backgroundColor: 'lightgrey',
                    flexDirection: 'column'
                    }}>
                    <PacmanLoader
                        color={color}
                        loading={loading}
                        size={75}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    <br />
                    <p style={{fontWeight:'bolder', fontSize: "26px" }}><Wave text="Generating AI results" effect="fadeOut" effectChange={0.5} /></p>
                </div>
            )
        }  
        else { 

             //Return to Login Screen once the user logs out
            if (this.props.account.account === null) {
                return (
                <Navigate to="/" />
                );
            }
            else {
                return (
                    this.renderReport()
                );
            }
        }
    }   
}

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);