
import React from 'react';
import { Link } from 'react-router-dom';

import { MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

//CSS
import './header.css';

//Redux
import { logoutUser } from '../../redux/ActionCreator';
import { useDispatch } from 'react-redux';

const Header = (props) => {

    const dispatch = useDispatch();
    const imageUrl = process.env.PUBLIC_URL + '/logo.png';

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <header>
            <div className="logo">
                <div className="row">
                    <div className="col">
                        <div className='row'>
                            <img src={imageUrl} className="img-logo" alt="DebtFixer Logo" />
                        </div>
                    </div>
                    <div className="col">
                        {/*Display name only if it is passed in*/}
                        {props.name ? <span className="alignCenter test">Welcome { props.name } </span> : '' } 
                    </div>
                    <div className="col">
                        <MDBBtn outline className='alignCenterLeft web-only' color='primary' onClick={ handleLogout }> {/* web_only class is only visible on desktop */}
                            Logout
                            {/*<Link to="/register">Create New</Link>*/}
                        </MDBBtn>
                    </div>
                </div>
            </div>
            <hr className='hr-style'/>
        </header>
    );
};

export default Header;