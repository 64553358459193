import React, { Component } from 'react';

import {
          MDBBtn,
          MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
        }
        
from 'mdb-react-ui-kit';
import './login.css';

import { connect } from 'react-redux';
import { loginUser, cookieLoginUser } from '../../redux/ActionCreator';

//Routing
import { Link } from 'react-router-dom';

//Home Page
import Home from '../HomeComponent/home';

//Sweet Alert
import Swal from 'sweetalert2';

const mapStateToProps = state => {
    return {
      login: state.account
    }    
}

const mapDispatchToProps = (dispatch) => ({
    loginUser: (email, password) => {dispatch(loginUser(email, password))},
    cookieLoginUser: (session) => {dispatch(cookieLoginUser(session))}
});

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
        this.buttonPressed = this.buttonPressed.bind(this);
    }

    /**
     * This function is called when the login button is pressed
     * @param e  The event object         
     */
    buttonPressed(e) {
        e.preventDefault();

        //Make sure the user entered a proper email
        if ((this.state.email === '') || (this.state.email.indexOf('@') === -1)) {
          Swal.fire({
            title: 'Error!',
            text: 'Please enter a valid email address',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        
        //Make sure user entered a password
        else if (this.state.password === '') {
          Swal.fire({
              title: 'Error!',
              text: 'Please enter a password',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }

        //Everything looks good, so login the user
        else {
          this.props.loginUser(this.state.email, this.state.password);
        }
    }

    /**
     * Autologin with Cookie
     */
    componentDidMount() {

      const existingSession = localStorage.getItem('CLGSession'); 
      
      //If we have a cookie, then login the user
      if (existingSession !== undefined && existingSession !== null) {
        this.props.cookieLoginUser(existingSession);
      }
    }

    /**
     * The return statement contains the JSX that will be rendered
     */
    render() {

      if (this.props.login.account !== null) {
        return (
          <Home />
        )
      }
      else {
        return (
            <MDBContainer className="my-5 gradient-form">
        
              <MDBRow>
        
                <MDBCol col='6' className="mb-5">
                  <div className="d-flex flex-column ms-5">
        
                    <div className="text-center">
                      <img src="logo.png"
                        style={{width: '185px'}} alt="logo" />
                      <h5 className="mt-5 mb-1 pb-1">Welcome back</h5>
                    </div>
        
                    <p>Please login to your account</p>
        
                    <MDBInput wrapperClass='mb-4' label='Email address' id='form1' type='email' onChange={ (e) => this.setState({ email: e.target.value }) }/>
                    <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' onChange={ (e) => this.setState({ password: e.target.value }) }/>
        
        
                    <div className="text-center pt-1 mb-5 pb-1">
                      <MDBBtn className="mb-4 w-100 gradient-custom-2" onClick={this.buttonPressed}>Sign in</MDBBtn>
                      <a className="text-muted" href="#!"><Link to="/forgotPassword">Forgot password?</Link></a>
                    </div>
        
                    <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                      <p className="mb-0">Don't have an account?</p>
                      <MDBBtn outline className='mx-2' color='primary'>
                        <Link to="/register">Create New</Link>
                      </MDBBtn>
                    </div>
        
                  </div>
        
                </MDBCol>
        
                <MDBCol col='6' className="mb-5">
                  <div className="d-flex flex-column justify-content-center">
                    <img src="/Signin.png" alt='' className='img-fluid'/>
                  </div>
        
                </MDBCol>
        
              </MDBRow>
        
              {/* Footer */}
              <MDBRow>
                <MDBCol className="mb-5">
                  <p className="bet_time">DebtFixer ©2023</p>
                </MDBCol>
              </MDBRow>

            </MDBContainer>
          );
        }
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);