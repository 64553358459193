import react, { Component } from 'react';
import Header from '../HeaderComponent/header';
import Footer from '../FooterComponent/footer';

import { connect } from 'react-redux';

//CSS
import './home.css';

//Routing
import { Link } from 'react-router-dom';

import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBBtnGroup } from 'mdb-react-ui-kit';

const mapStateToProps = state => {
    return {
      account: state.account
    }    
}

class Home extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * The very top label panel
     * @returns             Welcome to your AI-Enabled Credit Repair Platform! Box
     */
    newClientPanel() {
        return (
            <MDBContainer>
                <MDBRow>
                    <h1 className="d-flex flex-row text-center w-50 justify-content-center mb-3 mt-5">Welcome to Your AI-Enabled Credit Repair Platform!</h1>
                </MDBRow>
                <MDBRow>
                    <div className="d-flex flex-row text-center w-50 align-items-center justify-content-center mb-2">
                        <p>Welcome aboard! You've just taken the first step towards transforming your credit repair services. Our innovative AI-Enabled Credit Repair Platform is designed to streamline your workflow, provide comprehensive credit analysis, and ultimately empower your clients towards improved financial health. Click below to assis your next client.</p>
                    </div>
                </MDBRow>
                <div className="text-center pt-1 mb-5 pb-1">
                    <MDBBtn className="mb-4 w-25 h-75"><Link to="/client"><span className="button_style">New Client</span></Link></MDBBtn>
                </div>
                
            </MDBContainer>    
        )
    }

    /**
     * The 2nd top label panel
     * @returns             Here's how it works
     */
    hereIsHowItWorksPanel() {
        return (
            <MDBContainer className='mb-5'>
                <MDBRow>
                    <h1 className="d-flex flex-row text-center w-50 justify-content-center mb-3 mt-5">How it works</h1>
                </MDBRow>
                <MDBRow>
                    <div className="d-flex flex-row text-center w-50 align-items-center justify-content-center mb-2">
                        <p>Unveiling the Power of AI for Comprehensive Credit Analysis and Repair. See how our proprietary AI transforms your client interactions.</p>
                    </div>
                </MDBRow>
            </MDBContainer>    
        )
    }

    /**
     * The Two sided Panels
     * @returns 
     */
    twoSidedPanels(panelTitle, panelText, image, reverseSides) {
    
        //Text is on the left side and image is on the right side
        if (!reverseSides) {
            return (
                <div className='two_side_width'>
                    <MDBRow>
                    <MDBCol md='6' className='mb-4'>
                        <MDBRow>
                            <h1 className="d-flex flex-row text-center w-50 justify-content-center text_title_style">{panelTitle}</h1>
                        </MDBRow>
                        <MDBRow>
                            <div className="d-flex flex-row text-center w-50 align-items-center justify-content-center mb-2">
                                <p>{panelText}</p>
                            </div>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md='6' className='mb-4'>
                            <img src={image} className='img-fluid rounded z-depth-1' alt='CLGImage' />
                        </MDBCol>
                    </MDBRow>
                </div>  
            );
        }
        else {
            return (
                <div className='two_side_width'>
                    <MDBRow>
                        <MDBCol md='6' className='mb-4'>
                            <img src={image} className='img-fluid rounded z-depth-1' alt='CLGImage' />
                        </MDBCol>
                        <MDBCol md='6' className='mb-4'>
                            <MDBRow>
                                <h1 className="d-flex flex-row text-center w-50 justify-content-center text_title_style">{panelTitle}</h1>
                            </MDBRow>
                            <MDBRow>
                                <div className="d-flex flex-row text-center w-50 align-items-center justify-content-center mb-2">
                                    <p>{panelText}</p>
                                </div>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div> 
            );
        }
    }

    render() {

        if (this.props.account.account !== null) {

            return (
                <>
                    <Header name={this.props.account.account.name}/>
                    { this.newClientPanel() }
                    <hr className='hr-style'/>
                    { this.hereIsHowItWorksPanel() }
                    <br />
                    { this.twoSidedPanels('Automate Real-time Credit Report Analysis', 'Simply enter the client\'s details, and our platform will automatically put the client\'s credit report from the credit bureaus, offering an instant, AI-driven analysis identifying reasons for their credit score and suggesting improvement actions.','./Image1.png', false)}
                    { this.twoSidedPanels('Actionable Recommendations', 'The AI identifies accounts that could be disputed and situations where cease-and-desist letters may be required, providing a comprehensive credit improvement plan with possible debt settlement suggestions.','./Image2.png', true)}
                    { this.twoSidedPanels('Efficient Client Communication', 'Generate a secure link to the client\'s personalized report and share it with them. Track their credit repair progress and communicate efficiently within the platform, empowering your clients towards better financial health.','./Image3.png', false)}
                    <Footer />
                </>
            );
        }
    }
}

export default connect(mapStateToProps)(Home);
