export const baseUrl = 'https://prod.debtfixer.co/api/';
//export const baseUrl = 'http://localhost:8000/api/';

//Login Endpoint
export const loginURL = baseUrl + 'login';

//Register Endpoint
export const registerURL = baseUrl + 'register';

//Reset Password Endpoint
export const resetPasswordURL = baseUrl + 'resetPassword';

//Forgot Password Endpoint
export const forgotPasswordURL = baseUrl + 'forgotPassword';

//Analyzes the Client's Data
//export const analyzeURL = baseUrl + 'analysis';
export const analyzeURL = baseUrl + 'analysis';

//Debt Pay Pro Auth
export const debtPayProURL = 'https://api.debtpaypro.com/v1/auth/token';

//Debt Pay Pro Create Client
export const debtPayProCreateClientURL = 'https://api.debtpaypro.com/v1/contacts';

//Debt Pay Pro Get Report
export const debtPayProGetReportURL = 'https://api.debtpaypro.com/v1/contacts/id/get_credit_report';

//Upload Report to email to client
export const uploadReport = baseUrl + 'uploadReport';
