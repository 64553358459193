import { createSlice } from '@reduxjs/toolkit'

const creditReportReducer = createSlice({
  name: 'Credit Report',
  initialState: {
    data: '',
    processing: false
  },
  reducers: {
    setData(state, action) {
        state.data = action.payload
        state.processing = false
    },
    resetData(state, action) {
        state.data = ''
        state.processing = false
    },
    setProcessing(state, action) {
        state.processing = true
    },
  }
})

export const { setData, resetData, setProcessing } = creditReportReducer.actions
export default creditReportReducer.reducer