import React, { Component } from 'react';

import {
          MDBBtn,
          MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
        }
        
from 'mdb-react-ui-kit';
import './register.css';

//Home Page
import Home from '../HomeComponent/home';

import { connect } from 'react-redux';
import { registerUser } from '../../redux/ActionCreator';

//Routing
import { Link } from 'react-router-dom';

//Sweet Alert
import Swal from 'sweetalert2';

const mapStateToProps = state => {
    return {
      login: state.account
    }    
}

const mapDispatchToProps = (dispatch) => ({
  registerUser: (name, email, password) => {dispatch(registerUser(name, email, password))},
});

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: ''
        }
        this.buttonPressed = this.buttonPressed.bind(this);
    }

    /**
     * This function is called when the login button is pressed
     * @param e  The event object         
     */
    buttonPressed(e) {
        e.preventDefault();

        //Make sure the user entered a proper email
        if ((this.state.email === '') || (this.state.email.indexOf('@') === -1)) {
          Swal.fire({
            title: 'Error!',
            text: 'Please enter a valid email address',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        
        //Make sure user entered a password
        else if (this.state.password === '') {
          Swal.fire({
              title: 'Error!',
              text: 'Please enter a password',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }

        //Make sure user entered a name
        else if (this.state.name === '') {
          Swal.fire({
              title: 'Error!',
              text: 'Please enter a name',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }

        else {
          this.props.registerUser(this.state.name, this.state.email, this.state.password);
        }
    }

    /**
     * The return statement contains the JSX that will be rendered
     */
    render() {
        

      if (this.props.login.account !== null) {
        return (
          <Home />
        )
      }

      else{

        return (
            <MDBContainer className="my-5 gradient-form">
        
              <MDBRow>
                <MDBCol col='6' className="mb-5">
                  <div className="d-flex flex-column ms-5">
        
                    <div className="text-center">
                      <img src="logo.png"
                        style={{width: '185px'}} alt="logo" />
                      <h4 className="mt-5 mb-1 pb-1">Sign Up</h4>
                    </div>
        
                    <h6 className='mt-1 label-align-left'>Name</h6>
                    <MDBInput wrapperClass='mb-4' label='Enter your name' id='form1' type='text' onChange={ (e) => this.setState({ name: e.target.value }) }/>
                    
                    <h6 className='mt-1 label-align-left'>Email</h6>
                    <MDBInput wrapperClass='mb-4' label='Enter your email address' id='form1' type='email' onChange={ (e) => this.setState({ email: e.target.value }) }/>
                    
                    <h6 className='mt-1 label-align-left'>Password</h6>
                    <MDBInput wrapperClass='mb-4' label='Create a password' id='form2' type='password' onChange={ (e) => this.setState({ password: e.target.value }) }/>
        
        
                    <div className="text-center pt-1 mb-5 pb-1">
                      <MDBBtn className="mb-4 w-100 gradient-custom-2" onClick={this.buttonPressed}>Get Started</MDBBtn>
                    </div>
        
                    <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                      <p className="mb-0">Already have an account?</p>
                      <MDBBtn outline className='mx-2' color='primary'>
                        <Link to="/">Sign in</Link>
                      </MDBBtn>
                    </div>
        
                  </div>
        
                </MDBCol>
        
                <MDBCol col='6' className="mb-5">
                  <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
        
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <img src="/Stars.png" alt='' className='img-fluid' />
                      <h1 className="mb-1 label-align-left">Unlock your</h1>
                      <h1 className="mb-4 label-align-left">potential with AI</h1>
                      <p className="medium mb-0 label-align-left">Welcome to our AI-Enabled Credit Analysis and Repair Platform! Empower yourself
                        with cutting-edge technnology to provide efficient, personalized credit repair services that truly make a difference.
                      </p>
                      <br/>
                      <p className="medium mb-0 label-align-left">As a sales representative, you will gain access to our robust AI tools that instantly analyze credit reports, 
                      identify disputable accounts, suggest possible debt settlements, and propose a tailor-made credit improvement plan for your clients. This
                      unique platform streamlines your work, freeing you to foxus more on client relationships and less on manual analysis. 
                      </p>
                    </div>
        
                  </div>
        
                </MDBCol>
        
              </MDBRow>

              {/* Footer */}
              <MDBRow>
                <MDBCol className="mb-5">
                  <p className="bet_time">DebtFixer ©2023</p>
                </MDBCol>
              </MDBRow>
              
            </MDBContainer>
            
          );
        }
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);